
const FletchOptions = [
    {
        image: "/images/fletching/arrow_shaft.png",
        name: "Arrow shaft",
        xp: 0.3,
        lvlreq: 1,
    },
    {
        image: "/images/fletching/Headless_arrow.png",
        name: "Headless Arrow",
        xp: 1,
        lvlreq: 1,
    },
    {
        image: "/images/fletching/Bronze_arrow.png",
        name: "Bronze Arrow",
        xp: 1.3,
        lvlreq: 1,
    },
    {
        image: "/images/fletching/Bronze_javelin.png",
        name: "Bronze Javelin",
        xp: 1,
        lvlreq: 3,
    },
    {
        image: "/images/fletching/Ogre_arrow_1.png",
        name: "Ogre Arrow",
        xp: 1,
        lvlreq: 5,
    },
    {
        image: "/images/fletching/Shortbow.png",
        name: "Shortbow",
        xp: 5,
        lvlreq: 5,
    },
    {
        image: "/images/fletching/Shortbow_(u).png",
        name: "Shortbow (u)",
        xp: 5,
        lvlreq: 5,
    },
    {
        image: "/images/fletching/Bronze_bolts_1.png",
        name: "Bronze Bolts",
        xp: 0.5,
        lvlreq: 9,
    },
    {
        image: "/images/fletching/Bronze_crossbow.png",
        name: "Bronze Crossbow",
        xp: 6,
        lvlreq: 9,
    },
    {
        image: "/images/fletching/Wooden_stock.png",
        name: "Wooden Stock",
        xp: 6,
        lvlreq: 9,
    },
    {
        image: "/images/fletching/Bronze_crossbow_(u).png",
        name: "Bronze Crossbow (u)",
        xp: 12,
        lvlreq: 9,
    },
];

export default FletchOptions;