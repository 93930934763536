
const HerbOptions = [
    {
        image: "/images/herblore/Guam_leaf_detail.png?5ad48",
        name: "Guam",
        xp: 2.5,
        lvlreq: 3,
        herb: true,
    },
    {
        image: "/images/herblore/Attack_potion(3).png?5ad48",
        name: "Attack Potion (3)",
        xp: 25,
        lvlreq: 3,
    },
    {
        image: "/images/herblore/Marrentill_detail.png?5ad48",
        name: "Marrentill",
        xp: 3.8,
        lvlreq: 5,
        herb: true,
    },
    {
        image: "/images/herblore/Antipoison(3).png?5ad48",
        name: "Antipoison (3)",
        xp: 37.5,
        lvlreq: 5,
    },
    {
        image: "/images/herblore/Relicym's_balm(3).png?5ad48",
        name: "Relicym's Balm (3)",
        xp: 40,
        lvlreq: 8,
    },
    {
        image: "/images/herblore/Tarromin_detail.png?5ad48",
        name: "Tarromin",
        xp: 5,
        lvlreq: 11,
        herb: true,
    },
    {
        image: "/images/herblore/Strength_potion(3).png?5ad48",
        name: "Strength Potion (3)",
        xp: 50,
        lvlreq: 12,
    },
    {
        image: "/images/herblore/Serum_207_(3).png?5ad48",
        name: "Serum 207 (3)",
        xp: 50,
        lvlreq: 15,
    },
    {
        image: "/images/herblore/Guthix_rest(3).png?5ad48",
        name: "Guthix Rest",
        xp: 59,
        lvlreq: 15,
    },
    {
        image: "/images/herblore/Guam_tar.png?5ad48",
        name: "Guam tar",
        xp: 30,
        lvlreq: 19,
    },
    {
        image: "/images/herblore/Harralander.png?5ad48",
        name: "Harralander",
        xp: 6.3,
        lvlreq: 20,
        herb: true,
    },
    {
        image: "/images/herblore/Compost_potion(3).png?5ad48",
        name: "Compost Potion (3)",
        xp: 60,
        lvlreq: 22,
    },
    {
        image: "/images/herblore/Restore_potion(3).png?5ad48",
        name: "Restore Potion (3)",
        xp: 62.5,
        lvlreq: 22,
    },
    {
        image: "/images/herblore/Ranarr_weed.png?5ad48",
        name: "Ranarr",
        xp: 7.5,
        lvlreq: 25,
        herb: true,
    },
    {
        image: "/images/herblore/Energy_potion(3).png?5ad48",
        name: "Energy Potion",
        xp: 67.5,
        lvlreq: 26,
    },
    {
        image: "/images/herblore/Toadflax.png?5ad48",
        name: "Toadflax",
        xp: 8,
        lvlreq: 30,
        herb: true,
    },
    {
        image: "/images/herblore/Defence_potion(3).png?5ad48",
        name: "Defence Potion (3)",
        xp: 75,
        lvlreq: 30,
    },
    {
        image: "/images/herblore/Marrentill_tar.png?5ad48",
        name: "Marrentill tar",
        xp: 42.5,
        lvlreq: 31,
    },
    {
        image: "/images/herblore/Agility_potion(3).png?5ad48",
        name: "Agility Potion (3)",
        xp: 80,
        lvlreq: 34,
    },
    {
        image: "/images/herblore/Combat_potion(3).png?5ad48",
        name: "Combat Potion (3)",
        xp: 84,
        lvlreq: 36,
    },
    {
        image: "/images/herblore/Prayer_potion(3).png?5ad48",
        name: "Prayer Potion (3)",
        xp: 87.5,
        lvlreq: 38,
    },
    {
        image: "/images/herblore/Tarromin_tar.png?5ad48",
        name: "Tarromin tar",
        xp: 55,
        lvlreq: 39,
    },
    {
        image: "/images/herblore/Irit_leaf.png?5ad48",
        name: "Irit Leaf",
        xp: 8.8,
        lvlreq: 40,
        herb: true,
    },
    {
        image: "/images/herblore/Harralander_tar.png?5ad48",
        name: "Harralander tar",
        xp: 72.5,
        lvlreq: 44,
    },
    {
        image: "/images/herblore/Super_attack(3).png?5ad48",
        name: "Super Attack (3)",
        xp: 100,
        lvlreq: 45,
    },
    {
        image: "/images/herblore/Avantoe.png?5ad48",
        name: "Avantoe",
        xp: 10,
        lvlreq: 48,
        herb: true,
    },
    {
        image: "/images/herblore/Superantipoison(3).png?5ad48",
        name: "Superantipoison (3)",
        xp: 106.3,
        lvlreq: 48,
    },
    {
        image: "/images/herblore/Fishing_potion(3).png?5ad48",
        name: "Fishing Potion (3)",
        xp: 112.5,
        lvlreq: 50,
    },
    {
        image: "/images/herblore/Super_energy(3).png?5ad48",
        name: "Super Energy (3)",
        xp: 117.5,
        lvlreq: 52,
    },
    {
        image: "/images/herblore/Hunter_potion(3).png?5ad48",
        name: "Hunter Potion (3)",
        xp: 120,
        lvlreq: 53,
    },
    {
        image: "/images/herblore/Kwuarm.png?5ad48",
        name: "Kwuarm",
        xp: 11.3,
        lvlreq: 54,
        herb: true,
    },
    {
        image: "/images/herblore/Irit_tar.png?5ad48",
        name: "Irit tar",
        xp: 85,
        lvlreq: 55,
    },
    {
        image: "/images/herblore/Super_strength(3).png?5ad48",
        name: "Super Strength (3)",
        xp: 125,
        lvlreq: 55,
    },
    {
        image: "/images/herblore/Magic_essence(3).png?5ad48",
        name: "Magic Essence (3)",
        xp: 130,
        lvlreq: 57,
    },
    {
        image: "/images/herblore/Snapdragon.png?5ad48",
        name: "Snapdragon",
        xp: 11.8,
        lvlreq: 59,
        herb: true,
    },
    {
        image: "/images/herblore/Weapon_poison.png?5ad48",
        name: "Weapon Poison",
        xp: 137.5,
        lvlreq: 60,
    },
    {
        image: "/images/herblore/Super_restore(3).png?5ad48",
        name: "Super Restore (3)",
        xp: 142.5,
        lvlreq: 63,
    },
    {
        image: "/images/herblore/Cadantine.png?5ad48",
        name: "Cadantine",
        xp: 12.5,
        lvlreq: 65,
        herb: true,
    },
    {
        image: "/images/herblore/Sanfew_serum(3).png?5ad48",
        name: "Sanfew Serum (3)",
        xp: 160,
        lvlreq: 65,
    },
    {
        image: "/images/herblore/Super_defence(3).png?5ad48",
        name: "Super Defence (3)",
        xp: 150,
        lvlreq: 66,
    },
    {
        image: "/images/herblore/Lantadyme.png?5ad48",
        name: "Lantadyme",
        xp: 13.1,
        lvlreq: 67,
        herb: true,
    },
    {
        image: "/images/herblore/Antidote+(4).png?5ad48",
        name: "Antidote+ (4)",
        xp: 155,
        lvlreq: 68,
    },
    {
        image: "/images/herblore/Antifire_potion(3).png?5ad48",
        name: "Antifire Potion (3)",
        xp: 157.5,
        lvlreq: 69,
    },
    {
        image: "/images/herblore/Divine_super_attack_potion(3).png?5ad48",
        name: "Divine Super Attack Potion (4)",
        xp: 2,
        lvlreq: 70,
    },
    {
        image: "/images/herblore/Divine_super_defence_potion(3).png?5ad48",
        name: "Divine Super Defence Potion (4)",
        xp: 2,
        lvlreq: 70,
    },
    {
        image: "/images/herblore/Divine_super_strength_potion(3).png?5ad48",
        name: "Divine Super Strength Potion (4)",
        xp: 2,
        lvlreq: 70,
    },
    {
        image: "/images/herblore/Dwarf_weed.png?5ad48",
        name: "Dwarf weed",
        xp: 13.8,
        lvlreq: 70,
        herb: true,
    },
    {
        image: "/images/herblore/Ranging_potion(3).png?5ad48",
        name: "Ranging Potion (3)",
        xp: 162.5,
        lvlreq: 72,
    },
    {
        image: "/images/herblore/Weapon_poison+.png?5ad48",
        name: "Weapon Posion+",
        xp: 165,
        lvlreq: 73,
    },
    {
        image: "/images/herblore/Divine_ranging_potion(3).png?5ad48",
        name: "Divine Ranging Potion (4)",
        xp: 2,
        lvlreq: 74,
    },
    {
        image: "/images/herblore/Torstol.png?5ad48",
        name: "Torstol",
        xp: 15,
        lvlreq: 75,
        herb: true,
    },
    {
        image: "/images/herblore/Magic_potion(3).png?5ad48",
        name: "Magic Potion (3)",
        xp: 172.5,
        lvlreq: 76,
    },
    {
        image: "/images/herblore/Stamina_potion(3).png?5ad48",
        name: "Stamina Potion (3)",
        xp: 76.5,
        lvlreq: 77,
    },
    {
        image: "/images/herblore/Stamina_potion(3).png?5ad48",
        name: "Stamina Potion (4)",
        xp: 102,
        lvlreq: 77,
    },
    {
        image: "/images/herblore/Divine_magic_potion(3).png?5ad48",
        name: "Divine Magic Potion (4)",
        xp: 2,
        lvlreq: 78,
    },
    {
        image: "/images/herblore/Zamorak_brew(3).png?5ad48",
        name: "Zamorak Brew (3)",
        xp: 175,
        lvlreq: 78,
    },
    {
        image: "/images/herblore/Antidote++(4).png?5ad48",
        name: "Antidote++ (4)",
        xp: 177.5,
        lvlreq: 79,
    },
    {
        image: "/images/herblore/Bastion_potion(3).png?5ad48",
        name: "Bastion Potion (3)",
        xp: 155,
        lvlreq: 80,
    },
    {
        image: "/images/herblore/Battlemage_potion(3).png?5ad48",
        name: "Battlemage Potion (3)",
        xp: 155,
        lvlreq: 80,
    },
    {
        image: "/images/herblore/Saradomin_brew(3).png?5ad48",
        name: "Saradomin Brew (3)",
        xp: 180,
        lvlreq: 81,
    },
    {
        image: "/images/herblore/Weapon_poison++.png?5ad48",
        name: "Weapon Poison++",
        xp: 190,
        lvlreq: 82,
    },
    {
        image: "/images/herblore/Extended_antifire(3).png?5ad48",
        name: "Extended Antifire (3)",
        xp: 82.5,
        lvlreq: 84,
    },
    {
        image: "/images/herblore/Extended_antifire(3).png?5ad48",
        name: "Extended Antifire (4)",
        xp: 110,
        lvlreq: 84,
    },
    {
        image: "/images/herblore/Ancient_brew(4).png?5ad48",
        name: "Ancient Brew (4)",
        xp: 190,
        lvlreq: 85,
    },
    {
        image: "/images/herblore/Divine_bastion_potion(4).png?5ad48",
        name: "Divine Bastion Potion (4)",
        xp: 2,
        lvlreq: 86,
    },
    {
        image: "/images/herblore/Divine_battlemage_potion(4).png?5ad48",
        name: "Divine Battlemage Potion (4)",
        xp: 2,
        lvlreq: 86,
    },
    {
        image: "/images/herblore/Anti-venom(3).png?5ad48",
        name: "Anti-venom (3)",
        xp: 90,
        lvlreq: 87,
    },
    {
        image: "/images/herblore/Anti-venom(4).png?5ad48",
        name: "Anti-venom (4)",
        xp: 120,
        lvlreq: 87,
    },
    {
        image: "/images/herblore/Menaphite_remedy(3).png?5ad48",
        name: "Menaphite Remedy (3)",
        xp: 200,
        lvlreq: 88,
    },
    {
        image: "/images/herblore/Super_combat_potion(4).png?5ad48",
        name: "Super Combat Potion (4)",
        xp: 150,
        lvlreq: 90,
    },
    {
        image: "/images/herblore/Forgotten_brew(4).png?5ad48",
        name: "Forgotten Brew (4)",
        xp: 145,
        lvlreq: 91,
    },
    {
        image: "/images/herblore/Super_antifire_potion(4).png?5ad48",
        name: "Super Antifire Potion (4)",
        xp: 130,
        lvlreq: 92,
    },
    {
        image: "/images/herblore/Anti-venom+(4).png?5ad48",
        name: "Anti-venom+ (4)",
        xp: 125,
        lvlreq: 94,
    },
    {
        image: "/images/herblore/Divine_super_combat_potion(4).png?5ad48",
        name: "Divine Super Combat Potion (4)",
        xp: 2,
        lvlreq: 97,
    },
    {
        image: "/images/herblore/Extended_super_antifire(3).png?5ad48",
        name: "Extended Super Antifire (3)",
        xp: 120,
        lvlreq: 98,
    },
    {
        image: "/images/herblore/Extended_super_antifire(3).png?5ad48",
        name: "Extended Super Anitifire (4)",
        xp: 160,
        lvlreq: 98,
    },
];

export default HerbOptions;