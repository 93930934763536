import { useState } from "react";
import {
    Grid,
    Box,
    GridItem,
    IconButton,
    useColorMode,
    Heading,
    useColorModeValue,
    Flex,
} from "@chakra-ui/react";
import {
    MoonIcon,
    SunIcon
} from "@chakra-ui/icons";
import LevelInput from "./LevelInput";
import { PrayerList } from "./calculators/PrayerList";
import { HerbloreList } from "./calculators/HerbloreList";
import { FletchingList } from "./calculators/FletchingList";
import CalculatorSwitcher from "./CalculatorSwitcher";

enum CalculatorTypes {
    PRAYER = 1,
    HERBLORE = 2,
    FLETCHING = 3,
};

const CalculatorName: Record<CalculatorTypes, string> = {
    [CalculatorTypes.PRAYER]: "Prayer",
    [CalculatorTypes.HERBLORE]: "Herblore",
    [CalculatorTypes.FLETCHING]: "Fletching",
};

interface CalculatorContentProps {
    currentCalculator: CalculatorTypes;
}

const CalculatorContent: React.FC<CalculatorContentProps> = ({ currentCalculator }) => {
    switch (currentCalculator) {
        case CalculatorTypes.PRAYER:
            return <PrayerList />;
        case CalculatorTypes.HERBLORE:
            return <HerbloreList />;
        case CalculatorTypes.FLETCHING:
            return <FletchingList />;
        default:
            return <div>Unknown Calculator</div>;
    }
};

const CalculatorUI = () => {
    const { colorMode, toggleColorMode } = useColorMode();
    const [ currentCalculator, setCalculator ] = useState(1);
    const bg = useColorModeValue("white", "gray.800")


    return (
        <Flex direction="column" alignItems="center">
            <Box>
                <CalculatorSwitcher currentCalculator={currentCalculator} setCalculator={setCalculator}/>
            </Box>
        <Box maxW="98vw" boxShadow="dark-lg" bg={bg} padding={2} m={2} borderWidth='1px' borderRadius='lg' overflow="scroll">
                {/* Title and Dark Mode Button */}
                <Grid templateColumns="repeat(2, 1fr)" alignItems="center" justifyContent="space-between">
                    <GridItem>
                        <Heading size="md">{CalculatorName[currentCalculator as CalculatorTypes]} XP Calculator</Heading>
                    </GridItem>
                    <GridItem justifySelf="end">
                        <IconButton aria-label="Dark/Light Mode" icon={colorMode === 'light' ? <MoonIcon/> : <SunIcon/>} onClick={toggleColorMode}/>
                    </GridItem>
                </Grid>
                <Grid templateRows="repeat (2, 1fr)" gap={2}>
                    {/* XP/Level Display */}
                    <LevelInput/>
                    {/* Current Calculator */}
                    <CalculatorContent currentCalculator={currentCalculator}/>
                </Grid>
            </Box>
        </Flex>
    )
};

export default CalculatorUI;