import { 
  ChakraProvider, 
  ColorModeScript,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import theme from './configuration/theme';
import { XPProvider } from "./Contexts/XPContext";
import CalculatorUI from './components/CalculatorUI';

const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode}/>
      <Flex minH="100vh" maxH="100vh" alignItems="center" overflow="auto" direction="column"
        bgImage={"/images/background-blur.jpg"} bgSize="cover" bgRepeat="no-repeat" bgPosition="center">
        <Heading size="lg" textShadow='0 0 25px black' color="white">OldschoolRS XP Calculator</Heading>
        <XPProvider>
          <CalculatorUI/>
        </XPProvider>
        <Text textShadow='0 0 25px black' fontSize="sm" mb={2} color="white">Made with ❤️ by <a href="mailto:samalew@outlook.com">Samyol</a></Text>
      </Flex>
    </ChakraProvider>
  );
};

export default App;