
import { useState, createContext, ReactNode, useContext } from 'react';
import LevelExperience from '../data/LevelExperience';

interface XPContextType {
    currentXP: number;
    setCurrentXP: React.Dispatch<React.SetStateAction<number>>;
    targetXP: number;
    setTargetXP: React.Dispatch<React.SetStateAction<number>>;
    xpDifference: number;
    setXPDifference: React.Dispatch<React.SetStateAction<number>>;
    currentLevel: number;
    setCurrentLevel: React.Dispatch<React.SetStateAction<number>>;
    targetLevel: number;
    setTargetLevel: React.Dispatch<React.SetStateAction<number>>;
    XPMultiplier: number;
    setXPMultiplier: React.Dispatch<React.SetStateAction<number>>;
}

const XPContext = createContext<XPContextType | undefined>(undefined);

interface XPProviderProps {
    children: ReactNode;
}

export const calculateLevel = (xp: number) => {
    let closestLevel: number = 0;

    for (let level in LevelExperience) {
        const exp: number = LevelExperience[level];

        if( exp <= xp ) {
            closestLevel = parseInt(level);
        } else {
            break;
        }
    }

    return closestLevel;
}; 

export const XPProvider: React.FC<XPProviderProps> = ({ children }) => {
    const [currentXP, setCurrentXP] = useState<number>(LevelExperience[1]);
    const [targetXP, setTargetXP] = useState<number>(LevelExperience[2]);
    const [xpDifference, setXPDifference] = useState<number>(targetXP - currentXP);
    const [currentLevel, setCurrentLevel] = useState<number>(calculateLevel(currentXP));
    const [targetLevel, setTargetLevel] = useState<number>(calculateLevel(targetXP));
    const [XPMultiplier, setXPMultiplier] = useState<number>(1);
  
    return (
      <XPContext.Provider value={{
        currentXP,
        setCurrentXP,
        targetXP,
        setTargetXP,
        xpDifference,
        setXPDifference,
        currentLevel,
        setCurrentLevel,
        targetLevel,
        setTargetLevel,
        XPMultiplier,
        setXPMultiplier,
      }}>
        {children}
      </XPContext.Provider>
    );
};

export const useXPContext = (): XPContextType => {
    const context = useContext(XPContext);
    if (!context) {
      throw new Error('useXPContext must be used within an XPProvider');
    }
    return context;
};