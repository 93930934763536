
const PrayerOptions = [
    {
        image: "/images/prayer/Guppy_detail.png",
        name: "Guppy",
        xp: 4,
        ensouled: false,
    },
    {
        image: "/images/prayer/Bones_detail.png",
        name: "Bones",
        xp: 4.5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Burnt_bones_detail.png",
        name: "Burnt Bones",
        xp: 4.5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Wolf_bones_detail.png?eed8a",
        name: "Wolf Bones",
        xp: 4.5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Blessed_bone_shards_detail.png?eed8a",
        name: "Blessed Bone Shards",
        xp: 5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Monkey_bones_detail.png?eed8a",
        name: "Monkey Bones",
        xp: 5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Bat_bones_detail.png?eed8a",
        name: "Bat Bones",
        xp: 5.3,
        ensouled: false,
    },
    {
        image: "/images/prayer/Cavefish_detail.png?eed8a",
        name: "Cavefish",
        xp: 7,
        ensouled: false,
    },
    {
        image: "/images/prayer/Fiendish_ashes_detail.png?eed8a",
        name: "Fiendish Ashes",
        xp: 10,
        ensouled: false,
    },
    {
        image: "/images/prayer/Tetra_detail.png?eed8a",
        name: "Tetra",
        xp: 10,
        ensouled: false,
    },
    {
        image: "/images/prayer/Big_bones_detail.png?eed8a",
        name: "Big Bones",
        xp: 10,
        ensouled: false,
    },
    {
        image: "/images/prayer/Jogre_bones_detail.png?eed8a",
        name: "Jogre Bones",
        xp: 15,
        ensouled: false,
    },
    {
        image: "/images/prayer/Catfish_detail.png?eed8a",
        name: "Catfish",
        xp: 16,        
        ensouled: false,
    },
    {
        image: "/images/prayer/Wyrmling_bones_detail.png?eed8a",
        name: "Wyrmling Bones",
        xp: 21,
        ensouled: false,
    },
    {
        image: "/images/prayer/Zogre_bones_detail.png?eed8a",
        name: "Zogre Bones",
        xp: 22.5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Shaikahan_bones_detail.png?eed8a",
        name: "Shaikahan Bones",
        xp: 25,
        ensouled: false,
    },
    {
        image: "/images/prayer/Vile_ashes_detail.png?eed8a",
        name: "Vile Ashes",
        xp: 25,
        ensouled: false,
    },
    {
        image: "/images/prayer/Babydragon_bones_detail.png?eed8a",
        name: "Babydragon Bones",
        xp: 30,
        ensouled: false,
    },
    {
        image: "/images/prayer/Loar_remains_detail.png?eed8a",
        name: "Loar Remains",
        xp: 33,
        ensouled: false,
    },
    {
        image: "/images/prayer/Phrin_remains_detail.png?eed8a",
        name: "Phrin Remains",
        xp: 46.5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Wyrm_bones_detail.png?eed8a",
        name: "Wyrm Bones",
        xp: 50,
        ensouled: false,
    },
    {
        image: "/images/prayer/Riyl_remains_detail.png?eed8a",
        name: "Riyl Remains",
        xp: 59.5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Malicious_ashes_detail.png?eed8a",
        name: "Malicious Ashes",
        xp: 65,
        ensouled: false,
    },
    {
        image: "/images/prayer/Dragon_bones_detail.png?eed8a",
        name: "Dragon Bones",
        xp: 72,        
        ensouled: false,
    },
    {
        image: "/images/prayer/Wyvern_bones_detail.png?eed8a",
        name: "Wyvern Bones",
        xp: 72,
        ensouled: false,
    },
    {
        image: "/images/prayer/Drake_bones_detail.png?eed8a",
        name: "Drake Bones",
        xp: 80,
        ensouled: false,
    },
    {
        image: "/images/prayer/Asyn_remains_detail.png?eed8a",
        name: "Asyn Remains",
        xp: 82.5,
        ensouled: false,
    },
    {
        image: "/images/prayer/Fayrg_bones_detail.png?eed8a",
        name: "Fayrg Bones",
        xp: 84,
        ensouled: false,
    },
    {
        image: "/images/prayer/Fiyr_remains_detail.png?eed8a",
        name: "Fiyr Remains",
        xp: 84,
        ensouled: false,
    },
    {
        image: "/images/prayer/Abyssal_ashes_detail.png?eed8a",
        name: "Abyssal Ashes",
        xp: 85,
        ensouled: false,
    },
    {
        image: "/images/prayer/Lava_dragon_bones_detail.png?eed8a",
        name: "Lava Dragon Bones",
        xp: 85,
        ensouled: false,
    },
    {
        image: "/images/prayer/Raurg_bones_detail.png?eed8a",
        name: "Raurg Bones",
        xp: 96,        
        ensouled: false,
    },
    {
        image: "/images/prayer/Hydra_bones_detail.png?eed8a",
        name: "Hydra Bones",
        xp: 110,
        ensouled: false,
    },
    {
        image: "/images/prayer/Infernal_ashes.png?eed8a",
        name: "Infernal Ashes",
        xp: 110,
        ensouled: false,
    },
    {
        image: "/images/prayer/Urium_remains_detail.png?eed8a",
        name: "Urium Remains",
        xp: 120,
        ensouled: false,
    },
    {
        image: "/images/prayer/Dagannoth_bones_detail.png?eed8a",
        name: "Dagannoth Bones",
        xp: 120,
        ensouled: false,
    }, 
    {
        image: "/images/prayer/Ensouled_goblin_head.png?8fa13",
        name: "Ensouled Goblin Head",
        xp: 130,
        ensouled: true,
    }, 
    {
        image: "/images/prayer/Ourg_bones_detail.png?eed8a",
        name: "Ourg Bones",
        xp: 140,
        ensouled: false,
    },
    {
        image: "/images/prayer/Superior_dragon_bones_detail.png?eed8a",
        name: "Superior Dragon Bones",
        xp: 150,
        ensouled: false,
    },
    {
        image: "/images/prayer/Ensouled_imp_head.png?8fa13",
        name: "Ensouled Imp Head",
        xp: 286,
        ensouled: true,
    }, 
    {
        image: "/images/prayer/Ensouled_minotaur_head.png?8fa13",
        name: "Ensouled Minotaur Head",
        xp: 364,
        ensouled: true,
    }, 
    {
        image: "/images/prayer/Ensouled_scorpion_head.png?8fa13",
        name: "Ensouled Scorpion Head",
        xp: 454,
        ensouled: true,
    }, 
    {
        image: "/images/prayer/Ensouled_bear_head.png?8fa13",
        name: "Ensouled Bear Head",
        xp: 480,
        ensouled: true,
    }, 
    {
        image: "/images/prayer/Ensouled_unicorn_head.png?8fa13",
        name: "Ensouled Unicorn Head",
        xp: 494,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_dog_head.png?8fa13",
        name: "Ensouled Dog Head",
        xp: 520,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_chaos_druid_head.png?8fa13",
        name: "Ensouled Chaos Druid Head",
        xp: 584,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_giant_head_detail.png?8fa13",
        name: "Ensouled Giant Head",
        xp: 650,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_ogre_head.png?8fa13",
        name: "Ensouled Ogre Head",
        xp: 716,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_elf_head.png?8fa13",
        name: "Ensouled Elf Head",
        xp: 754,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_troll_head.png?8fa13",
        name: "Ensouled Troll Head",
        xp: 780,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_horror_head.png?8fa13",
        name: "Ensouled Horror Head",
        xp: 832,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_kalphite_head.png?8fa13",
        name: "Ensouled Kalphite Head",
        xp: 884,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_dagannoth_head.png?8fa13",
        name: "Ensouled Dagannoth Head",
        xp: 936,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_bloodveld_head.png?8fa13",
        name: "Ensouled Bloodveld Head",
        xp: 1040,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_tzhaar_head.png?8fa13",
        name: "Ensouled TzHaar Head",
        xp: 1104,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_demon_head.png?8fa13",
        name: "Ensouled Demon Head",
        xp: 1170,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_hellhound_head.png?8fa13",
        name: "Ensouled Hellhound Head",
        xp: 1200,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_aviansie_head.png?8fa13",
        name: "Ensouled Aviansie Head",
        xp: 1234,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_abyssal_head.png?8fa13",
        name: "Ensouled Abyssal Head",
        xp: 1300,
        ensouled: true,
    },
    {
        image: "/images/prayer/Ensouled_dragon_head.png?8fa13",
        name: "Ensouled Dragon Head",
        xp: 1560,
        ensouled: true,
    },
]

export default PrayerOptions;