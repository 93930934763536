import {
    Flex,
    Box,
    Image,
    Button,
    useColorModeValue
} from '@chakra-ui/react';
import { useXPContext } from "../Contexts/XPContext";

interface ChildProps {
    currentCalculator: number;
    setCalculator: React.Dispatch<React.SetStateAction<number>>;
}

const CalculatorSwitcher: React.FC<ChildProps> = ({currentCalculator, setCalculator}) => {
    const {
        setXPMultiplier
    } = useXPContext();

    const setNewCalculator = (value: number) => {
        setCalculator(value);
        setXPMultiplier(1);
    }

    const bg = useColorModeValue("white", "gray.800")

    return (
        <Box m={2} mb={0} padding={2} borderWidth='1px' bg={bg} borderRadius='lg'>
            <Flex gap={2} overflowX="scroll">
                <Button 
                    onClick={() => setNewCalculator(1)} 
                    colorScheme="gray"
                    border={currentCalculator === 1 ? "2px" : "0px"}
                    borderColor='green.500'
                    flexShrink={0}
                >
                    <Image src="/images/skills/prayer_icon.png"/>
                </Button>
                <Button 
                    onClick={() => setNewCalculator(2)} 
                    colorScheme="gray"
                    border={currentCalculator === 2 ? "2px" : "0px"}
                    borderColor='green.500'
                    flexShrink={0}
                >
                    <Image src="/images/skills/herblore_icon.png"/>
                </Button>
                <Button 
                    onClick={() => setNewCalculator(3)} 
                    colorScheme="gray"
                    border={currentCalculator === 3 ? "2px" : "0px"}
                    borderColor='green.500'
                    flexShrink={0}
                >
                    <Image src="/images/skills/Fletching_icon.png"/>
                </Button>
            </Flex>
        </Box>
    ); 
};

export default CalculatorSwitcher;