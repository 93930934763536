import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Image,
    Grid,
    GridItem,
    Box,
    Checkbox,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import PrayerOptions from "./data/PrayerOptions";
import { useXPContext } from "../../Contexts/XPContext";
import { useState } from "react";

export const PrayerList = () => {
    const {
        xpDifference,
        XPMultiplier,
        setXPMultiplier
    } = useXPContext();

    const [ selectedCheckbox, setSelectedCheckbox ] = useState(null);

    const changeMultiplier = (val: number) => {
        setXPMultiplier(val);
        return val;
    };

    const handleCheckboxChange = (id: any, val: number) => {
        if (selectedCheckbox === id) {
            setSelectedCheckbox(null);
            changeMultiplier(1);
        } else {
            setSelectedCheckbox(id);
            changeMultiplier(val);
        }
    };

    return (
        <Grid maxH="70vh">
            <Grid templateColumns="repeat(3, 1fr)" gap={2} pb={2}>
                <GridItem>
                    <Box padding="1rem" borderWidth='1px' borderRadius='lg'>
                        <Checkbox isChecked={selectedCheckbox === "gAltar"} size='md' colorScheme='blue' id="gAltar" onChange={() => handleCheckboxChange("gAltar", 3.5)}>
                            Gilded Altar - 350%
                        </Checkbox>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box padding="1rem" borderWidth='1px' borderRadius='lg'>
                        <Checkbox isChecked={selectedCheckbox === "cAltar"} size='md' colorScheme='blue' id="cAltar" onChange={() => handleCheckboxChange("cAltar", 7)}>
                            Chaos Altar - 700%
                        </Checkbox>
                    </Box>
                </GridItem>
                <GridItem>
                    <Box padding="1rem" borderWidth='1px' borderRadius='lg'>
                        <Checkbox isChecked={selectedCheckbox === "ECto"} size='md' colorScheme='blue' id="ECto" onChange={() => handleCheckboxChange("ECto", 4)}>
                            Ectofunctus - 400%
                        </Checkbox>
                    </Box>
                </GridItem>
            </Grid>
            <GridItem padding={5} borderWidth='1px' borderRadius='lg' overflow="scroll">
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th> <ViewIcon boxSize={4}/> </Th>
                                <Th>Item</Th>
                                <Th>XP Given</Th>
                                <Th>Item Amount Req</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {PrayerOptions.map(opt => (
                                <Tr key={opt.name}>
                                    <Td><Image objectFit='contain' src={opt.image} boxSize={8}/></Td>
                                    <Td>{opt.name}</Td>
                                    <Td isNumeric={true}>{opt.xp}</Td>
                                    {!opt.ensouled ? (
                                        <Td isNumeric={true}>{Math.ceil(xpDifference / (XPMultiplier * opt.xp))}</Td>
                                    ) : (
                                        <Td isNumeric={true}>{Math.ceil(xpDifference / opt.xp)}</Td>
                                    )}
                                </Tr>    
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </GridItem>
        </Grid>
    );
};