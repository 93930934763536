import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Image,
    Grid,
    GridItem,
    Box,
    Flex,
    Checkbox,
    Text
} from '@chakra-ui/react';
import { useXPContext } from "../../Contexts/XPContext";
import { ViewIcon, WarningIcon } from '@chakra-ui/icons';
import HerbOptions from "./data/HerbOptions";
import { useState } from 'react';

export const HerbloreList = () => {
    const {
        xpDifference,
        XPMultiplier,
        currentLevel
    } = useXPContext();
    const [hideHerbs, setHideHerbs] = useState(true);

    const handleCheckboxChange = () => {
        setHideHerbs(prevState => !prevState);
    };

    return (
        <Grid maxH="70vh">
            <Grid templateColumns="repeat(2, auto)" gap={2}>
                <GridItem>
                    <Flex mb={2} gap={2}>
                        <Box padding="1rem" borderWidth='1px' borderRadius='lg'>
                            <Checkbox isChecked={hideHerbs} size='md' colorScheme='blue' onChange={handleCheckboxChange}>
                                Hide Herbs
                            </Checkbox>
                        </Box>     
                    </Flex>
                </GridItem>
                <GridItem>
                    <Flex mb={2} gap={2}>
                        <Box padding="1rem" borderWidth='1px' borderRadius='lg'>
                            <Flex direction="row" gap={1} align="center">
                                <WarningIcon boxSize={5} color="gray.600"/>
                                <Text>If you do not have the required level to create a potion or clean a herb, the XP will display red.</Text>
                            </Flex>
                        </Box>     
                    </Flex>
                </GridItem>
            </Grid>
            <GridItem padding={5} borderWidth='1px' borderRadius='lg' overflow="scroll">
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th> <ViewIcon boxSize={4}/> </Th>
                                <Th>Item</Th>
                                <Th>XP Given</Th>
                                <Th>Item Amount Req</Th>
                                <Th>Required Level</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {HerbOptions.map(opt => {
                                if (hideHerbs && opt.herb) {
                                    return null;
                                }
                                return (
                                <Tr key={opt.name}>
                                    <Td><Image objectFit='contain' src={opt.image} boxSize={8}/></Td>
                                    <Td>{opt.name}</Td>
                                    <Td isNumeric={true}>{opt.xp}</Td>
                                    <Td isNumeric={true}>{Math.ceil(xpDifference / (XPMultiplier * opt.xp))}</Td>
                                    { currentLevel >= opt.lvlreq ? (
                                        <Td isNumeric={true}>{opt.lvlreq}</Td>
                                    ) : (
                                        <Td isNumeric={true} color="red.500">{opt.lvlreq}</Td>
                                    )}
                                </Tr>
                                )    
                            })}
                        </Tbody>
                    </Table>
                </TableContainer>
            </GridItem>
        </Grid>
    );
};