import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Image,
    Grid,
    GridItem,
} from '@chakra-ui/react';
import { useXPContext } from "../../Contexts/XPContext";
import { ViewIcon } from '@chakra-ui/icons';
import FletchOptions from './data/FletchOptions';

export const FletchingList = () => {
    const {
        xpDifference,
        XPMultiplier,
        currentLevel
    } = useXPContext();

    return (
        <Grid maxH="70vh" overflow="scroll">
            <GridItem padding={5} borderWidth='1px' borderRadius='lg' overflow="scroll">
                <TableContainer>
                    <Table variant="simple">
                        <Thead>
                            <Tr>
                                <Th> <ViewIcon boxSize={4}/> </Th>
                                <Th>Item</Th>
                                <Th>XP Given</Th>
                                <Th>Item Amount Req</Th>
                                <Th>Required Level</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {FletchOptions.map(opt => (
                                <Tr key={opt.name}>
                                    <Td><Image objectFit='contain' src={opt.image} boxSize={8}/></Td>
                                    <Td>{opt.name}</Td>
                                    <Td isNumeric={true}>{opt.xp}</Td>
                                    <Td isNumeric={true}>{Math.ceil(xpDifference / (XPMultiplier * opt.xp))}</Td>
                                    { currentLevel >= opt.lvlreq ? (
                                        <Td isNumeric={true}>{opt.lvlreq}</Td>
                                    ) : (
                                        <Td isNumeric={true} color="red.500">{opt.lvlreq}</Td>
                                    )}
                                </Tr>
                                )    
                            )}
                        </Tbody>
                    </Table>
                </TableContainer>
            </GridItem>
        </Grid>
    );
};