import { 
    Grid,
    GridItem,
    Text,
    NumberInput,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    NumberInputField,
    Switch,
    Stack,
 } from "@chakra-ui/react"
import { useState } from 'react';
import LevelExperience from "../data/LevelExperience";
import { useXPContext, calculateLevel } from "../Contexts/XPContext";

const LevelInput = () => {
    const [ experienceSwitch, setExperienceSwitch ] = useState(false);
    const {
        currentXP,
        setCurrentXP,
        targetXP,
        setTargetXP,
        setXPDifference,
        currentLevel,
        setCurrentLevel,
        targetLevel,
        setTargetLevel,
    } = useXPContext();

    const handleXPChange = (newCurrentXP: number, newTargetXP: number) => {
        setCurrentXP(newCurrentXP);
        setTargetXP(newTargetXP);
        setXPDifference(newTargetXP - newCurrentXP);
        setCurrentLevel(calculateLevel(newCurrentXP));
        setTargetLevel(calculateLevel(newTargetXP));
    };

    const toggleXPSwitch = (e: any) => {
        setExperienceSwitch(e.target.checked);
    };

    return(
        <>
        {!experienceSwitch ?  (
            <Grid templateColumns="repeat(3, 1fr)">
                <GridItem justifySelf="start">
                    <Text fontSize="md">Current Level:</Text>
                        <NumberInput min={1} value={currentLevel} max={targetLevel - 1} onChange={(value: any) => handleXPChange(LevelExperience[value], targetXP)}>
                                <NumberInputField />
                                <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                                </NumberInputStepper>
                        </NumberInput>
                </GridItem>

                <GridItem justifySelf="center" alignContent="center" alignSelf="center">
                    <Stack align="center">
                        <Text fontSize="md">Level/XP Toggle</Text>
                        <Switch onChange={toggleXPSwitch}></Switch>
                    </Stack>
             </GridItem>

                <GridItem justifySelf="end">
                    <Text fontSize="md">Target Level:</Text>
                        <NumberInput min={currentLevel+1} value={targetLevel} onChange={(value: any) => handleXPChange(currentXP, LevelExperience[value])}>
                                <NumberInputField />
                                <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                                </NumberInputStepper>
                        </NumberInput>

                </GridItem>
            </Grid>
            ) : (
                <Grid templateColumns="repeat(3, 1fr)">
                <GridItem justifySelf="start">
                    <Text fontSize="md">Current XP:</Text>
                        <NumberInput value={currentXP} min={0} max={targetXP-1} onChange={(value: any) => handleXPChange(value, targetXP)}>
                                <NumberInputField />
                                <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                                </NumberInputStepper>
                        </NumberInput>
                </GridItem>

                <GridItem justifySelf="center" alignContent="center" alignSelf="center">
                    <Stack align="center">
                        <Text fontSize="md">Level/XP Toggle</Text>
                        <Switch onChange={toggleXPSwitch}></Switch>
                    </Stack>
                </GridItem>

                <GridItem justifySelf="end">
                    <Text fontSize="md">Target XP:</Text>
                        <NumberInput value={targetXP} min={currentXP} onChange={(value: any) => handleXPChange(currentXP, value)}>
                                <NumberInputField />
                                <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                                </NumberInputStepper>
                        </NumberInput>

                </GridItem>
            </Grid>
            )}
        </>
    )
}

export default LevelInput;